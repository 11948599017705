<template>
  <div>
    <v-card>
      <v-app-bar dark color="primary">
        <v-icon large class="mr-3">data_usage</v-icon>
        <v-toolbar-title>User Count</v-toolbar-title>
      </v-app-bar>
      <v-card-text>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header class="header-text">
              Filters and Count
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row no-gutters>
                <v-col>
                  <v-menu
                    ref="startDateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="dataToSend.startDate"
                        label="From"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      no-title
                      scrollable
                      v-model="dataToSend.startDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.startDateMenu.save(Date(startDate))"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col>
                  <v-menu
                    ref="endDateMenu"
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="endDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="To"
                        v-model="dataToSend.endDate"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>

                    <v-date-picker
                      no-title
                      color="primary"
                      scrollable
                      v-model="dataToSend.endDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.endDateMenu.save(Date(endDate))"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col sm="6">
                  <v-autocomplete
                    :items="countries"
                    prepend-icon="mdi-map-marker"
                    item-text="title"
                    item-value="title"
                    v-model="dataToSend.country"
                    clearable
                    autocomplete
                    label="Countries"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row class="justify-center align-center" no-gutters>
                <v-btn class="ma-2" @click="loadData()" color="primary"
                  >Get Count</v-btn
                >
                <v-btn class="ma-2" @click="getUsers()" color="primary"
                  >Get Users</v-btn
                >
                <v-btn class="ma-2" @click="clearFilter()" color="primary"
                  >Clear Filter</v-btn
                >
              </v-row>

              <v-row justify="center">
                <v-col cols="12" md="6" sm="12">
                  <v-card color="#076827" dark height="200px">
                    <div class="d-flex flex-no-wrap justify-space-between">
                      <v-card-title class="font-weight-thin ml-3">
                        Total User Count
                        <h1 class="font-weight-light ml-3">
                          <number
                            :from="0"
                            :to="totalUsers"
                            :format="(number) => number.toFixed(0)"
                            :duration="1"
                            :delay="1"
                            easing="Power3.easeOut"
                          />
                        </h1>
                      </v-card-title>
                      <v-avatar size="100" tile>
                        <v-icon x-large>group</v-icon>
                      </v-avatar>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <v-row justify="end" align="center" class="mt-6">
        <!-- Search Input Field with Larger Text -->
        <v-col cols="auto">
          <v-text-field
            v-model="searchEmail"
            label="Search by Email"
            prepend-icon="mdi-magnify"
            hide-details
            solo
            class="search-field large-text-field"
            style="max-width: 300px"
          ></v-text-field>
        </v-col>

        <!-- Search Button -->
        <v-col cols="auto">
          <v-btn
            color="primary"
            class="ml-2"
            @click="searchUsersByEmail"
            elevation="1"
            outlined
          >
            Search
          </v-btn>
        </v-col>

        <!-- Reset Button -->
        <v-col cols="auto">
          <v-btn
            color="secondary"
            class="ml-2"
            @click="resetSearch"
            elevation="1"
            outlined
          >
            Reset
          </v-btn>
        </v-col>

        <!-- Filter by Code Button -->
        <v-col cols="auto">
          <v-btn
            color="primary"
            class="ml-2"
            @click="goToFilteredUsers"
            elevation="1"
            outlined
          >
            Filter By Code
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="filteredUsers"
        class="elevation-1 mt-4"
      >
        <template v-slot:item.showProfile="{ item }">
          <v-btn
            variant="text"
            color="primary"
            @click="goToUserProfile(item._id)"
          >
            Show Full Profile
          </v-btn>
        </template>
      </v-data-table>

      <div class="text-xs-center pt-2">
        <v-pagination
          color="primary"
          v-model="pagination.page"
          :length="Math.ceil(totalDocs / 10)"
          :total-visible="7"
          @input="(pageNumber) => getUsers(pageNumber)"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import RestResource from "../../..//services/dataServiceMobileV2.js";
const service = new RestResource();

export default {
  data() {
    return {
      userFilter: {},
      users: [],
      filteredUsers: [],
      totalDocs: 0,
      nextPage: 1,
      pagination: {},
      totalUsers: 0,
      lastDate: [],
      countries: [],
      startDate: null,
      endDate: null,
      startDateMenu: false,
      endDateMenu: false,
      searchEmail: "",
      dataToSend: {
        startDate: undefined,
        endDate: undefined,
      },
      headers: [
        { text: "userId", value: "_id" },
        { text: "First Name", value: "firstName" },
        { text: "Email", value: "email" },
        { text: "Joined At", value: "createdAt" },
        { text: "Actions", value: "showProfile", sortable: false },
      ],
    };
  },

  mounted() {
    this.getCountry();
    this.getTotalUsers();
    this.getUsers();
  },

  methods: {
    async getTotalUsers(params) {
      this.$setLoader();
      let res = await service.fetchUsers(params);
      this.totalUsers = res.data.data;
      this.$disableLoader();
    },

    async getCountry() {
      let res = await service.getUniqueCountries();
      this.countries = res.data.data;
    },

    clearFilter() {
      this.dataToSend.startDate = undefined;
      this.dataToSend.endDate = undefined;
      this.dataToSend.country = undefined;
    },

    async loadData() {
      let v = await this.$validator.validateAll();
      if (v) {
        this.getTotalUsers(this.dataToSend);
      }
    },

    async getUsers(pageNumber) {
      this.userFilter.page = pageNumber ? pageNumber : this.nextPage;

      if (this.dataToSend.startDate) {
        this.userFilter.startDate = this.dataToSend.startDate;
      }

      if (this.dataToSend.endDate) {
        this.userFilter.endDate = this.dataToSend.endDate;
      }

      this.$setLoader();
      let res = await service.getUsers(this.userFilter);
      this.users = res.data.data.users;
      this.filteredUsers = this.users;
      this.nextPage = res.data.data.nextPage;
      this.totalDocs = res.data.data.totalDocs;
      this.$disableLoader();
    },

    async searchUsersByEmail() {
      if (this.searchEmail) {
        this.$setLoader();
        let res = await service.getUserByEmail({ text: this.searchEmail });
        this.filteredUsers = res.data.data;
        this.$disableLoader();
      } else {
        this.getUsers();
      }
    },

    resetSearch() {
      this.searchEmail = "";
      this.getUsers();
    },

    goToUserProfile(userId) {
      this.$router.push({ name: "MobileV2UserProfile", params: { userId } });
    },

    goToFilteredUsers() {
      this.$router.push({ name: "MobileV2FilteredUsers" })
    }
  },
};
</script>

<style scoped>
.large-text-field input {
  height: 48px; /* Adjust height as needed */
  font-size: 1.1rem; /* Adjust font size as needed */
}

.search-field input {
  border-radius: 4px;
}

.v-btn {
  min-width: 120px;
  transition: background-color 0.2s;
}

.v-btn:hover {
  background-color: #1976d2 !important; /* Example hover color */
  color: white !important;
}
</style>
